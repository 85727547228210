import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import CardEntity from "../entity";
import Swal from 'sweetalert2';

export default class CardList extends DefaultController {
    private editModal: any;
    //@ts-ignore
    private datatables: any[];

    async init() {
        this.entity = "cards";
        this.datatables = [];
        this.editModal = new bootstrap.Modal((document.querySelector("#editCard") as HTMLElement));
        (document.querySelectorAll(".tab-pane[data-location-id]") as NodeListOf<HTMLElement>).forEach((elem) => {
            this.createTable(elem);
        });
        await super.init();
    }

    getEntityData(elem: any) {
        const location_id = (document.querySelector(".tab-pane.active.show") as HTMLElement).getAttribute("data-location-id")
        return {...CardEntity.getEntityData(elem), location_id: location_id}
    }

    bindListeners() {
        jQuery(".tab-content").delegate(".delete-record", "click", async (e: any) => {
            const confirm = await Swal.fire({
                title: 'Sind Sie sicher?',
                text: "Das Löschen kann nicht rückgängig gemacht werden!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ja, löschen',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('card.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('card.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatables.forEach((table: any) => {
                    table.ajax.reload();
                })
            }
        });
        jQuery(".tab-content").delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editCardForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form)
                this.editModal.show();
            }

        });
        (document.querySelector("#addNewCardForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#addNewCardForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.datatables.forEach((table: any) => {
                        table.ajax.reload();
                    })
                    const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddCard") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('card.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });

        (document.querySelector("#editCardForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editCardForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.datatables.forEach((table: any) => {
                        table.ajax.reload();
                    })
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('card.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }

    createTable(elem: any) {
        const locationId = elem.getAttribute("data-location-id")
        const table = elem.querySelector("table")
        const datatable = jQuery(table).DataTable({
            initComplete: () => {
                jQuery(elem).find(".loading-body").addClass("d-none")
                jQuery(elem).find(".table-responsive").removeClass("d-none")
            },
            ajax: `/api/v1/cards?location_id=${locationId}`,
            columns: [
                {data: 'name'},
                {data: 'access_room'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 2,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddCard'
                    }
                }
            ]
        });
        this.datatables.push(datatable);
    }
}